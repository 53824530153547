<template>
  <div class="home">
    <v-container fluid class="py-0">
      <v-row class="my-0 bloque-intro" align="center">
        <v-col class="py-0 px-0">
          <template v-if="home.cover.image">
            <v-img
              :src="
                'https://huesodevacacms.actstudio.xyz/storage/uploads/' +
                home.coverMovil.path
              "
              height="calc(100vh - 80px)"
              width="100%"
              class="d-sm-flex d-none"
            ></v-img>
          </template>
          <template v-if="home.coverMovil.video">
            <video :src="
                'https://huesodevacacms.actstudio.xyz/storage/uploads/' +
                home.coverMovil.path" muted loop autoplay style="height: calc(100vh - 80px); width: 100%; object-fit: cover" class="d-sm-none">
            </video>
          </template>
          <template v-if="home.coverMovil.image">
            <v-img
              :src="
                'https://huesodevacacms.actstudio.xyz/storage/uploads/' +
                home.cover.path
              "
              class="d-sm-none mx-auto"
              max-width="200"
            ></v-img>
          </template>
          <template v-if="home.cover.video">
            <video :src="
                'https://huesodevacacms.actstudio.xyz/storage/uploads/' +
                home.cover.path" muted loop autoplay style="height: calc(100vh - 80px); width: 100%; object-fit: cover" class="d-none d-sm-inline">
            </video>
          </template>
        </v-col>
      </v-row>
    </v-container>

    <template v-if="grillingSpecial.active">
      <div style="background-color: #232222">
        <v-container fluid class="py-0">
          <v-row class="my-0" align="center">
            <v-col class="py-0 px-0" cols="12" sm="6">
              <v-img
                :src="'https://huesodevacacms.actstudio.xyz/'+grillingSpecial.cover.path"
                height="calc(100vh - 80px)"
                class="align-end pb-3 d-none d-sm-flex"
              >
                <v-img
                  src="../assets/img/grilling-day.png"
                  max-width="500"
                  class="ml-6"
                ></v-img>
              </v-img>

              <v-img
                :src="'https://huesodevacacms.actstudio.xyz/'+grillingSpecial.cover.path"
                class="align-end pb-3 d-sm-none"
              >
                <v-img
                  src="../assets/img/grilling-day.png"
                  max-width="285"
                  class="ml-6"
                ></v-img>
              </v-img>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-container class="py-0">
                <v-row class="my-0">
                  <v-col offset-sm="2" class="py-0">
                    <span
                      class="druk-wide-bold d-block white--text"
                      style="font-size: 40px"
                      >{{grillingSpecial.name}}</span
                    >
                    <span
                      class="druk-medium d-block white--text"
                      style="font-size: 35px"
                      >{{grillingSpecial.subName}}</span
                    >
                    <p class="white--text d-block" style="max-width: 350px">
                      {{grillingSpecial.description}}
                    </p>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </template>

    <div style="background-color: #efe0cc">
      <v-container class="py-0" fluid>
        <v-row class="bloque-menu my-0">
          <v-col order="1" order-sm="0" class="py-0 px-0" cols="0" sm="6">
            <template v-for="(categoria, c) in categorias">
              <v-img
                :src="
                  'https://huesodevacacms.actstudio.xyz/' + categoria.cover.path
                "
                :key="'categoria-cover-' + c"
                height="100%"
                v-show="c == menu"
              ></v-img>
            </template>
          </v-col>
          <v-col order="0" order-sm="1" align-self="center">
            <template v-for="(categoria, c) in categorias">
              <router-link
                to="/menu"
                :key="'categoria-cover-' + c"
                class="d-block my-3"
              >
                <v-hover v-slot="{ hover }">
                  <div>
                    <v-img
                      :src="
                        'https://huesodevacacms.actstudio.xyz/' +
                        categoria.iconoSeleccionado.path
                      "
                      max-width="500"
                      class="mx-auto cursor-pointer"
                      v-show="hover"
                    >
                    </v-img>
                    <v-img
                      :src="
                        'https://huesodevacacms.actstudio.xyz/' +
                        categoria.icono.path
                      "
                      max-width="500"
                      class="mx-auto cursor-pointer"
                      @mouseover="menu = c"
                      v-show="!hover"
                    >
                    </v-img>
                  </div>
                </v-hover>
              </router-link>
            </template>
          </v-col>
        </v-row>
        <v-row style="min-height: calc(100vh - 80px)" class="my-0">
          <v-col cols="12" sm="6" class="px-0 py-0">
            <div style="background-color: #f59386; position: relative">
              <v-container fluid class="py-0">
                <v-row justify="center" class="my-0">
                  <v-col cols="11" sm="10" class="py-0">
                    <a
                      href="whatsapp://send?phone=+5218132327194&abid=+5218132327194"
                      class="druk-wide-bold d-block banner-order-now not-decorated"
                      >ORDENA AHORA</a
                    >
                  </v-col>
                </v-row>
              </v-container>
            </div>

            <v-container fluid class="py-0">
              <v-row justify="center">
                <v-col cols="11" sm="10">
                  <a
                    href="tel:8132327194"
                    class="druk-wide-bold d-block not-decorated black--text mt-12 phone-size"
                    ><span class="druk-medium">T.</span>8132327194</a
                  >
                </v-col>
              </v-row>

              <v-row justify="center" class="my-10">
                <v-col cols="11" sm="10">
                  <span class="d-block">Lunes a Sábado</span>
                  <span class="d-block mb-6">13:00 — 23:00 Hrs</span>

                  <span class="d-block">Domingo</span>
                  <span class="d-block mb-6">13:00 — 16:30 Hrs</span>

                  <!-- <span class="d-block">Calle Ejemplo #123 Col. Ejemplo</span>
                  <span class="d-block">Monterrey</span> -->
                </v-col>
              </v-row>

              <v-row justify="center" class="mt-10">
                <v-col cols="11" sm="10" class="py-6 py-sm-0">
                  <a
                    href="https://www.instagram.com/huesodevaca/"
                    class="d-inline-block druk-medium black--text not-decorated social-networks-size"
                    target="_blank"
                    >INSTAGRAM</a
                  >
                  <a
                    href="https://www.facebook.com/huesodevacabbq"
                    class="d-inline-block druk-medium black--text not-decorated social-networks-size pl-6"
                    target="_blank"
                    >FACEBOOK</a
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col cols="12" sm="6" class="px-0 py-0">
            <v-img
              src="../assets/img/footer.jpg"
              style="height: 100%"
            ></v-img>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  data () {
    return {
      menu: 0,
      categorias: [],
      home: {
        cover: {
          path: ''
        },
        coverMovil: {
          path: ''
        }
      },
      grillingSpecial: {
        cover: {
          path: ''
        }
      }
    }
  },
  components: {},
  beforeMount: function () {
    const aux = this
    fetch(
      'https://huesodevacacms.actstudio.xyz/api/collections/get/Categorias',
      {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          filter: {},
          fields: {},
          sort: {}
        })
      }
    )
      .then((res) => res.json())
      .then(function (res) {
        aux.categorias = res.entries
      })
    fetch('https://huesodevacacms.actstudio.xyz/api/singletons/get/Home')
      .then((data) => data.json())
      .then(function (data) {
        aux.home = data
      })

    fetch('https://huesodevacacms.actstudio.xyz/api/singletons/get/GrillingSpecial')
      .then((data) => data.json())
      .then(function (data) {
        aux.grillingSpecial = data
      })
  }
}
</script>
